<template>
  <div class="CaseDetails">
    <banner/>
    <div class="CaseDetails-product">
      <div class="CaseDetails-product-content">
        <div class="content_top">
          <p>{{ title }}</p>
          <div id="time"><span>发表日期：{{ new Date(date).toLocaleDateString() }}</span></div>
        </div>
        <div id="content" v-html="content">

        </div>
<!--        <div id="content_bottom">-->
<!--          <div id="nextorbefore">-->
<!--            <p>上一篇：<a href="http://localhost:8080" target="_blank">落地式自助借还机</a></p>-->
<!--            <p>下一篇：<a href="http://localhost:8080" target="_blank">落地式自助借还机</a></p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "../../components/Banner";
export default {
  components: {
    Banner
  },
  data() {
    return {
      title:'',
      date:'',
      content:''
    };
  },
  created() {
    document.documentElement.scrollTop = 0
    this.type = localStorage.getItem('type')
    this.title = localStorage.getItem('title')
    this.date = localStorage.getItem('date')
    this.content = localStorage.getItem('content')
    console.log(this.content)
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.CaseDetails {
  width: 100%;
  height: 100%;
  //overflow: hidden;
  &-product {
    width: 1200px;
    margin: 40px auto;
    .content_top{
      text-align: center;
      border-bottom: solid 1px #eee;
      padding-bottom: 30px;
      >p {
        line-height: 32px;
        font-size: 24px;
        color: #333;
      }
      #time{
        line-height: 20px;
        font-size: 14px;
        color: #666;
        margin-top: 20px;
      }
    }
    #content{
      padding: 40px 30px;
      box-sizing: border-box;
      border-bottom: solid 1px #eee;
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      text-align: justify;
    }
    #content_bottom{
      width: 1200px;
      margin-top: 40px;
      #nextorbefore{
        margin-top: 10px;
        p{
          color: #666;
          font-family: 宋体;
          font-size: 15px;
          white-space: normal;
          line-height: 1.8em;
          a{
            color: #666;
          }
          a:hover{
            color: #0a00da;

          }
        }
      }
    }
  }
}
</style>